import { ReqStatus } from "../interfaces/req";

export const reqStatus: ReqStatus[] = [
  "В работе",
  "Водитель назначен",
  "Завершена",
  "Запланирована",
  "Новая",
  "Отменена",
  "ЧП",
];
