import { useEffect, useState, SyntheticEvent } from "react"
import { TextField, Autocomplete } from "@mui/material"
import SETTINGS from "../../settings"
import MyForm from "../../components/MyForm"
import { myFetch } from "../../utils"
import { useNavigate, useParams, useOutletContext } from "react-router-dom"
import reqEditFields from "../../form-fields/req-edit"

const ReqAdditionalFields = ({ reqId, req, setReq, addAs, setAddAs, aserv, setAserv, errors, setErrors }: {
    reqId: string | undefined
    req: any
    setReq: Function
    addAs: boolean
    aserv: any
    setAserv: Function
    setAddAs: Function
    errors: any
    setErrors: Function
}) => {
    const baseOptions: any = {'test': []}
    const [options, setOptions] = useState(baseOptions)
    const baseLoading: any = {}
    const [loading, setLoading] = useState(baseLoading)
    const baseState: any = {}
    const [state, setState] = useState(baseState)
    const navigate = useNavigate()
    const handleInputChange = (index: string) => {
        return async (
            event: SyntheticEvent<Element, Event>,
            newInputValue: string,
        ) => {
            setLoading(true);
            try {
                const response = await myFetch(
                    `${SETTINGS.SITE_URL}/api/v1/reqs/services/`,
                    {},
                    navigate,
                );
                const data = await response.json();
                setOptions({...options, [index]: data.results || []});
            } catch (error) {
                console.error('Ошибка при запросе:', error);
            }
            setLoading(false);
        };
    }

    return <>
        <div className="form d-flex flex-wrap">
            <div className="form__title">Дополнительные услуги</div>
            {
                req.additional_services.map((as1: any, index: number) => {
                    return <div className="form__row" style={{
                        border: as1.to_delete ? (as1.id ? '1px solid red' : '1px solid orange') : ''
                    }}>
                        <div className="form__row-inner d-flex">
                            <div className="form__row-field">
                                <Autocomplete
                                    getOptionKey={(o: any) => o.id}
                                    getOptionLabel={(o: any) => o.name}
                                    options={options[index] || []}
                                    // error={error}
                                    loading={loading}
                                    onChange={(e, v) => {
                                        // console.log(key, v)
                                        as1.service = v
                                        setState(JSON.parse(JSON.stringify(state)))
                                    }}
                                    value={as1.service || null}
                                    isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
                                    onInputChange={handleInputChange(index.toString())}
                                    renderInput={(params) => {
                                        return <TextField
                                            error={errors?.additional_services?.[index]?.[0] || null}
                                            helperText={errors?.additional_services?.[index]?.[0] || null}
                                            {...params}
                                            label="Вид услуги"
                                            variant="outlined"
                                        />
                                    }}
                                // className={className}
                                />

                            </div>
                            <div className="form__row-field">
                                <TextField
                                    value={as1.cost}
                                    label=""
                                    error={errors?.bank_accounts?.[index]?.account?.[0] || null}
                                    onChange={(e) => {
                                        as1.cost = e.target.value
                                        setReq({ ...req, additional_services: JSON.parse(JSON.stringify(req.additional_services)) })
                                    }}
                                    className="form__row-input"
                                />
                            </div>
                            <div className="form__row-action d-flex block-center" onClick={
                                () => {
                                    as1.to_delete = !as1.to_delete
                                    setReq({ ...req, additional_services: JSON.parse(JSON.stringify(req.additional_services)) })
                                }
                            }>
                                <span className={`icon-${!as1.to_delete ? 'check-circle' : 'delete'} transition`}></span>{/*{cp1.to_delete ? 'Add' : 'Del'}*/}
                            </div>
                        </div>
                    </div>
                })
            }
            {
                addAs && <>
                    <div className="form__row">
                        <div className="form__row-inner d-flex">
                            <div className="form__row-field">
                                <Autocomplete
                                    getOptionKey={(o: any) => o.id}
                                    getOptionLabel={(o: any) => o.name}
                                    options={options['test'] || null}
                                    // error={error}
                                    loading={loading}
                                    onChange={(e, v) => {
                                        console.log(v)
                                        aserv.service = v
                                        setAserv(JSON.parse(JSON.stringify(aserv)))
                                    }}
                                    value={aserv.service || null}
                                    isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
                                    onInputChange={handleInputChange('test')}
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            label="Вид услуги"
                                            variant="outlined"
                                        />
                                    }}
                                // className={className}
                                />

                            </div>
                            <div className="form__row-field">
                                <TextField
                                    type="number"
                                    value={aserv.cost}
                                    label=""
                                    onChange={(e) => {
                                        aserv.cost = e.target.value
                                        setReq({ ...req, additional_services: JSON.parse(JSON.stringify(req.additional_services)) })
                                    }}
                                    className="form__row-input"
                                />
                            </div>
                            <div className="form__row-action d-flex block-center" onClick={
                                () => {
                                    req.additional_services.push(aserv)
                                    setReq({ ...req, additional_services: JSON.parse(JSON.stringify(req.additional_services)) })
                                    setAserv({})
                                }
                            }>
                                <span className={`icon-${!aserv.to_delete ? 'check-circle' : 'delete'} transition`}></span>{/*{cp1.to_delete ? 'Add' : 'Del'}*/}
                            </div>

                        </div>
                    </div>
                </>
            }
            {
                !addAs && <button className="form__add d-flex items-center transition" type="button"
                    onClick={() => { setAddAs(true) }}
                >
                    Добавить услугу
                </button>
            }
        </div>
    </>
}



export default function ReqEdit() {
    // const reqId = props.match.params.id
    const navigate = useNavigate()
    const { reqId } = useParams()
    const r: any = {additional_services: []}
    const [req, setReq] = useState(r)
    const rh: any[] = []
    const [reqHistory, setReqHistory] = useState(rh)
    const [firstUpdated, setFirstUpdated] = useState(false)
    const baseErrors: any = {}
    const [errors, setErrors] = useState(baseErrors)
    const [addAs, setAddAs] = useState(false)
    const [aserv, setAserv] = useState({})
    // const {me}: {me: any } = useOutletContext()
    useEffect(() => {
        const fetchData = async () => {
            const res = await myFetch(
                `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`,
                {},
                navigate,
            )
            const res2 = await myFetch(
                `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/history/`,
                {},
                navigate,
            )
            setReq(await res.json())
            setReqHistory(await res2.json())
            setFirstUpdated(true)
        }
        fetchData()
    }, [reqId, navigate])
    return <>
        <div className="application-page d-flex flex-wrap">
            <div className="page-top page-top--black d-flex items-center">
                <div className="page-top__left d-flex items-center">
                    <a href="" className="page-top__back circle-center">
                        <span className="icon-angle-left"></span>
                    </a>
                    <span className="page-top__title">Заявка #5933</span>
                    <div className="page-top__status completed d-flex items-center">Завершен</div>{/* Завершен - completed, В работе - in-work */}
                </div>

                <div className="page-top__right d-flex items-center">
                    <div className="page-top__created d-flex items-center">
                        <div className="page-top__created-icon circle-center">
                            <span className="icon-calendar"></span>
                        </div>
                        <span className="page-top__created-date">Создан от 15 май 2024 · 15:30</span>
                        <span className="page-top__created-editing">Изменен 16 май 2024 · 17:42</span>
                    </div>

                    <div className="truck-info d-flex items-center">
                        <div className="truck-info__icon circle-center">
                            <span className="icon-truck"></span>
                        </div>

                        <span className="truck-info__text d-flex items-center">
                            Авто <span>· KAMAZ · 8 кубов · K213KK 116</span>
                        </span>
                    </div>

                    <div className="spent-fuel d-flex items-center">
                        <div className="spent-fuel__icon circle-center">
                            <span className="icon-drop"></span>
                        </div>
                        <span className="spent-fuel__text">Топливо потрачено <span>· 308 литров</span></span>
                    </div>
                </div>
            </div>

            <div className="application-page__top d-flex">
                <div className="application-page__top-item d-flex items-center transition">
                    <span className="icon-map transition"></span>
                    Посмотреть на карте
                </div>
                <div className="application-page__top-item d-flex items-center transition">
                    <span className="icon-close-circle transition"></span>
                    Отказ от заявки
                </div>
                <div className="application-page__top-item d-flex items-center transition">
                    <span className="icon-danger transition"></span>
                    Проблема с заявкой
                </div>
                <div className="application-page__top-item d-flex items-center transition">
                    <span className="icon-cube transition"></span>
                    Перегруз автотранспорта
                </div>
                <div className="application-page__top-item d-flex items-center transition">
                    <span className="icon-calendar transition"></span>
                    Перенос даты
                </div>
            </div>

            <div className="application-page__content">
                <MyForm
                    fields={reqEditFields}
                    method="PUT"
                    returnUrl={`/reqs/`}
                    formUrl={`${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`}
                    state={req}
                    setState={setReq}
                    firstUpdated={firstUpdated}
                    errors={errors}
                    setErrors={setErrors}
                    additionalFields={
                        <ReqAdditionalFields
                            reqId={reqId}
                            req={req}
                            setReq={setReq}
                            addAs={addAs}
                            aserv={aserv}
                            setAddAs={setAddAs}
                            setAserv={setAserv}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    }
                />
            </div>

            <div className="application-page__history">
                <h2 className="form__title">История заявки</h2>
                <div className="history-list">
                    <div className="history-item history-item--green">{/* add class 'history-item--green' or 'history-item--orange' */}
                        <div className="history-item__inner d-flex flex-wrap">
                            <div className="history-item__info d-flex justify-between">
                                <div className="history-item__info-column d-flex flex-column">
                                    <span className="history-item__info-text">{req.created_at}</span>
                                    <span className="history-item__info-title">Создание заявки</span>
                                </div>
                                <div className="history-item__info-column d-flex flex-column">
                                    <span className="history-item__info-text">Менеджер</span>
                                    <span className="history-item__info-manager d-flex items-center">
                                        {req.author?.full_name || '<Неизвестный>'}
                                        <span className="icon-profile-black"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        reqHistory.map((hist) => {
                            let title = 'Неизвестное действие'
                            let myClass = 'history-item-green'
                            if (hist.history_data.req_date) {
                                title = 'Изменение даты заявки'
                                myClass = 'history-item-orange'
                            } else if (hist.history_data.driver) {
                                title = 'Назначение водителя'
                            }
                            return hist && <div className={`history-item ${myClass}`}>{/* add class 'history-item--green' or 'history-item--orange' */}
                                <div className="history-item__inner d-flex flex-wrap">
                                    <div className="history-item__info d-flex justify-between">
                                        <div className="history-item__info-column d-flex flex-column">
                                            <span className="history-item__info-text">{hist.created_at}</span>
                                            <span className="history-item__info-title">{title}</span>
                                        </div>
                                        <div className="history-item__info-column d-flex flex-column">
                                            <span className="history-item__info-text">Менеджер</span>
                                            <span className="history-item__info-manager d-flex items-center">
                                                {hist.changer?.full_name}
                                                <span className="icon-profile-black"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </>
}
