import SETTINGS from '../settings'
import dayjs from 'dayjs'

const reqEditFields = [
    {
        header: 'Маршрут',
        fields: [
            {
                mykey: 'kind',
                label: 'Маршрут',
                fieldKind: 'select',
                nameField: 'name',
                options: [
                    {
                        value: 'Поставил - забрал',
                        name: 'Поставил - забрал',
                    },
                    {
                        value: 'Поставил',
                        name: 'Поставил',
                    },
                    {
                        value: 'Забрал',
                        name: 'Забрал',
                    },
                    {
                        value: 'Перестановка',
                        name: 'Перестановка',
                    },
                    {
                        value: 'Холостой рейс',
                        name: 'Холостой рейс',
                    },
                ],                
            }
        ],
    },
    {
        header: 'Клиент',
        fields: [
            {
                mykey: 'client',
                fieldKind: 'dict-select',
                label: 'Клиент',
                nameField: 'name',
                // shownNameField: 'full_name',
                // customClass: 'test-class',
                url: `${SETTINGS.SITE_URL}/api/v1/companies/`,
            },
            {
                mykey: 'client.contact_person',
                fieldKind: 'text',
                label: 'Контактное лицо',
            },
            {
                mykey: 'client.phone',
                fieldKind: 'text',
                label: 'Телефон',
            },
        ],
    },
    {
        fields: [
            {
                mykey: 'client.email',
                fieldKind: 'text',
                label: 'Почта',
            },
            {
                mykey: 'source',
                fieldKind: 'select',
                label: 'Источник заявки',
                options: [
                    {
                        value: 'FROM_SITE',
                        name: 'Заявка с сайта'
                    },
                    {
                        value: 'FROM_PHONE',
                        name: 'Заявка по звонку',
                    }
                ],
                nameField: 'name',
            },
            {
                mykey: 'author.full_name',
                fieldKind: 'text',
                label: 'Заявку создал',
                disabled: true,
            },
        ],
        header: ''
    },
    {
        header: 'Адреса',
        fields: [
            {
                mykey: 'address',
                fieldKind: 'address',
                label: 'Адрес загрузки контейнера',
                customClass: 'address',
            },
            {
                mykey: 'location',
                fieldKind: 'text',
                label: 'Адрес загрузки контейнера'
            },
            {
                mykey: 'container',
                fieldKind: 'dict-select',
                label: 'Забрать контейнер',
                nameField: 'num',
                url: `${SETTINGS.SITE_URL}/api/v1/containers/`,
            }
        ]
    },
    {
        fields: [{
            mykey: 'location_out',
            fieldKind: 'text',
            label: 'Адрес отгрузки контейнера'
        },
        {
            mykey: 'container_out',
            fieldKind: 'dict-select',
            label: 'Забрать контейнер',
            nameField: 'num',
            url: `${SETTINGS.SITE_URL}/api/v1/containers/`,
        }]
    },
    {
        header: 'Машина и водитель',
        fields: [
            {
                mykey: 'req_date',
                fieldKind: 'date',
                // defaultFun: () => {
                //     let d = new Date()
                //     d.setTime(d.getTime() - 1000 * 60 * 60 * 24 * 5)
                //     return dayjs(d)
                // },
                label: 'Дата рейса',
            },
            {
                mykey: 'driver',
                fieldKind: 'dict-select',
                label: 'Водитель',
                nameField: 'full_name',
                // shownNameField: 'full_name',
                url: `${SETTINGS.SITE_URL}/api/v1/drivers/`,
            },
            {
                mykey: 'vehicle',
                fieldKind: 'dict-select',
                label: 'Машина',
                nameField: 'license_plate',
                // shownNameField: 'full_name',
                url: `${SETTINGS.SITE_URL}/api/v1/vehicles/`,
            },
        ]
    },
    {
        header: 'Заявка',
        fields: [
            {
                mykey: 'total_sum',
                fieldKind: 'number',
                label: 'Сумма оплаты',
            },
            {
                mykey: 'payment_type',
                fieldKind: 'select',
                nameField: 'name',
                label: 'Тип оплаты',
                options: [
                    {
                        name: 'Расчетный счет',
                    },
                    {
                        name: 'Наличные',
                    }
                ],
            },
            {
                mykey: 'payment_status',
                fieldKind: 'select',
                nameField: 'name',
                label: 'Статус оплаты',
                options: [
                    {
                        name: 'Не оплачен',
                    },
                    {
                        name: 'Оплачен',
                    }
                ],
            },
            {
                mykey: 'dispatcher1',
                fieldKind: 'dict-select',
                label: 'Диспетчер',
                nameField: 'full_name',
                url: `${SETTINGS.SITE_URL}/api/v1/users/dispatchers/`,
            },
        ]
    },
    {
        fields: [
            {
                mykey: 'status',
                fieldKind: 'select',
                nameField: 'name',
                label: 'Статус',
                options: [
                    {
                        value: 'NEW',
                        name: 'Новая',
                    },
                    {
                        value: 'DRIVER_IS_APPOINTED',
                        name: 'Водитель назначен',
                    },
                    {
                        value: 'IN_WORK',
                        name: 'В работе',
                    },
                    {
                        value: 'FINISHED',
                        name: 'Завершена',
                    },
                ],
            },
            {
                mykey: 'comment',
                fieldKind: 'text',
                label: 'Комментарий',
            },
        ]
    }
]
export default reqEditFields
