import { styled } from "@mui/material";
import {
  DatePicker as DatePickerMiu,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { FC } from "react";

const DatePickerMui = styled(DatePickerMiu)(({}) => ({
  ".MuiInputLabel-root": {
    color: "#B2B3C3 !important",
    fontSize: "10px",
    top: "-6px !important",
  },
  ".MuiInputLabel-root[data-shrink='true']": {
    color: "#B2B3C3 !important",
    opacity: "1 !important",
    top: "16px !important",
  },
  ".MuiInputBase-root": {
    borderRadius: "10px",
    height: "100%",

    "& fieldset": {
      borderRadius: "10px",
      borderColor: "#EDEEF5",
    },
  },
  ".MuiInputBase-root fieldset legend": {
    display: "none",
  },
  ".MuiInputBase-root input": {
    padding: "22px 0 4px 16px",
    fontSize: "12px",
  },
  ".MuiInputBase-root fieldset": {
    top: 0,
  },
}));

type DatePickerProps = {
  onChange: (e: dayjs.Dayjs | null) => void;
};
export const DatePicker: FC<DatePickerProps> = ({ onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DatePickerMui
        label="Время выхода на линию"
        format="DD MMM YYYY"
        slotProps={{
          textField: {
            variant: "outlined",
          },
        }}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};
