import { useState, useEffect } from "react";
import { Company } from "../../interfaces/company";
import SETTINGS from "../../settings";
import { exportFileFatch, myFetch } from "../../utils";
import { NavLink, useNavigate, useOutletContext } from "react-router-dom";
import {
  Box,
  InputAdornment,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ModalTableFields from "../../components/ModalTableFields";
import { Select } from "../../components/select/Select";
import { downloadFile } from "../../utils/downloadFile";

const companyKindFilterItems = [
  {
    value: "ALL",
    label: "Все",
    aggKey: "count",
  },
  {
    value: "Клиент",
    label: "Клиенты",
    aggKey: "count_clients",
  },
  {
    value: "Исполнители",
    label: "Исполнители",
    aggKey: "count_doers",
  },
  {
    value: "Полигон",
    label: "Полиноны",
    aggKey: "count_polygons",
  },
];

const companyTableFields = [
  {
    mykey: "type",
    label: "Вид компании",
  },
  {
    mykey: "inn",
    label: "ИНН",
  },
  {
    mykey: "director",
    label: "Директор",
  },
  {
    mykey: "contact",
    label: "Контактное лицо",
  },
  {
    mykey: "phone",
    label: "Телефон",
  },
  {
    mykey: "email",
    label: "Эл. почта",
  },
];

const COMPANY_KIND_MAP: any = {
  Клиент: "client",
  Исполнители: "performer",
  Полигон: "polygon",
};

export default function CompanyList() {
  const cs1: Company[] = [];
  const [query, setQuery] = useState("");

  const { me }: { me: any } = useOutletContext();

  const navigate = useNavigate();
  const [currentKind, setCurrentKind] = useState("ALL");
  const [companies, setCompanies] = useState(cs1);
  const baseCounts: any = {
    count_clients: 0,
    count_doers: 0,
    count_polygons: 0,
    count: 0,
  };
  const [counts, setCounts] = useState(baseCounts);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const tableFieldsString = localStorage.getItem("companyTableFields");
  let tableFieldsObj;
  if (tableFieldsString) {
    tableFieldsObj = JSON.parse(tableFieldsString);
  } else {
    tableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [
    any,
    (state: any) => void
  ] = useState(tableFieldsObj);

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/companies/?query=${query}&kind=${currentKind}`,
        {},
        navigate
      );
      const { results, count, count_clients, count_polygons, count_doers } =
        await res.json();
      const cs: Company[] = results;
      setCompanies(cs);
      setCounts({
        count,
        count_clients,
        count_doers,
        count_polygons,
      });
    }
    fetchData();
  }, [navigate, currentKind, query]);

  const handleExportFile = async () => {
    const params = {
      kind: currentKind,
    };
    const response = await exportFileFatch(location.pathname, navigate, params);
    try {
      await downloadFile(response, location.pathname);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setQuery(event.target.value);
  };

  return (
    <>
      <ModalTableFields
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        state={tableFieldIsClosed}
        setState={setTableFieldIsClosed}
        tableFields={companyTableFields}
      />
      <div className="companies-page">
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">{`Компании · ${counts.count}`}</span>
            <span
              onClick={openModal}
              className="page-top__left-settings icon-setting transition"
            ></span>
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                placeholder="Поиск по названию и ИНН..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{" "}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink
              to={`/companies/add/`}
              className="page-top__button d-flex items-center transition"
            >
              Добавить компанию
            </NavLink>
          </div>
        </div>

        <div className="page-content">
          <div className="companies-page__top d-flex items-center justify-between pr-10">
            <div className="companies-page__filter d-flex">
              {companyKindFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    "cars-page__filter-item d-flex items-center transition" +
                    (item.value === currentKind ? " is-active" : "");
                  return (
                    <div
                      key={item.value}
                      className={classList}
                      onClick={() => {
                        setCurrentKind(item.value);
                        // setCurrentPaymentStatus('ALL')
                      }}
                    >{`${item.label} · ${counts[item.aggKey]}`}</div>
                  );
                }
              )}
              {/* {
                            reqPaymentKind.map((item: {
                                value: string
                                label: string
                                aggKey: string
                            }) => {
                                return <div
                                    className={'cars-page__filter-item d-flex items-center transition' + (
                                        item.value === currentPaymentStatus ? ' is-active' : ''
                                    )}
                                    onClick={() => {
                                        setCurrentPaymentStatus(item.value)
                                        setCurrentStatus('ALL')
                                    }}
                                >
                                    {`${item.label} · ${counts[item.aggKey]}`}
                                </div>
                            })
                        } */}
            </div>
            <Box sx={{ width: "100px" }}>
              <Select
                onChange={handleExportFile}
                itemList={["Экспорт в xlsx"]}
                value={""}
                label={"Действия"}
              />
            </Box>
          </div>

          <div className="companies-page__table">
            <table className="reqs-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input
                          type="checkbox"
                          className="table__checkbox-input"
                        />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      Id
                    </div>
                  </th>
                  {!tableFieldIsClosed.type && (
                    <th className="table__top-th th-type">Тип компании</th>
                  )}
                  <th className="table__top-th th-name">Наименование</th>
                  {!tableFieldIsClosed.inn && (
                    <th className="table__top-th th-inn">ИНН</th>
                  )}
                  {!tableFieldIsClosed.director && (
                    <th className="table__top-th th-director">Директор</th>
                  )}
                  {!tableFieldIsClosed.contact && (
                    <th className="table__top-th th-contact">
                      Контактное лицо
                    </th>
                  )}
                  {!tableFieldIsClosed.phone && (
                    <th className="table__top-th th-phone">Номер телефона</th>
                  )}
                  {!tableFieldIsClosed.email && (
                    <th className="table__top-th th-email">Почта</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {companies.map((c) => {
                  return (
                    <tr className="table__row" key={c.id}>
                      <td className="table__td td-id">
                        <div className="table__td-inner d-flex items-center">
                          <label className="table__checkbox">
                            <input
                              type="checkbox"
                              className="table__checkbox-input"
                            />
                            <div className="table__checkbox-block d-flex block-center transition">
                              <span className="icon-check"></span>
                            </div>
                          </label>
                          {c.id}
                        </div>
                      </td>
                      {!tableFieldIsClosed.type && (
                        <td className="table__td td-type">
                          <div className="table__td-inner d-flex items-center">
                            <div
                              className={`td-type__block ${
                                COMPANY_KIND_MAP[c.kind]
                              } d-flex items-center`}
                            >
                              {/* classes for types: client - Клиент, polygon - Полигон, performer - Исполнитель */}
                              {c.kind}
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="table__td td-name">
                        <div className="table__td-inner d-flex items-center">
                          {c.name}
                        </div>
                      </td>
                      {!tableFieldIsClosed.inn && (
                        <td className="table__td td-inn">
                          <div className="table__td-inner d-flex items-center">
                            {c.inn}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.director && (
                        <td className="table__td td-director">
                          <div className="table__td-inner d-flex items-center">
                            {c.director}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.contact && (
                        <td className="table__td td-contact">
                          <div className="table__td-inner d-flex items-center">
                            {c.contact_person}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.phone && (
                        <td className="table__td td-phone">
                          <div className="table__td-inner d-flex items-center">
                            {c.phone}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.email && (
                        <td className="table__td td-email">
                          <div className="table__td-inner d-flex items-center">
                            {c.email}
                          </div>
                        </td>
                      )}
                      <a
                        href={`/companies/${c.id}/edit/`}
                        className="table__edit circle-center transition"
                      >
                        <span className="icon-edit"></span>
                      </a>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
