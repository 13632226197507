import { useState } from "react"
import SETTINGS from "../../settings"
import MyForm from "../../components/MyForm"
import reqEditFields from "../../form-fields/req-edit"
import dayjs from 'dayjs'


export default function ReqAdd() {
    // const reqId = props.match.params.id
    const [req, setReq] = useState({})
    const baseErrors: any = {}
    const [errors, setErrors] = useState(baseErrors)
    return <MyForm
        fields={reqEditFields}
        method="POST"
        returnUrl={`/reqs/`}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/reqs/`}
        state={req}
        setState={setReq}
        firstUpdated={true}
        errors={errors}
        setErrors={setErrors}
    />
}
