import { useEffect, useState } from "react"
import SETTINGS from "../../settings"
import MyForm from "../../components/MyForm"
import { myFetch } from "../../utils"
import {useNavigate, useParams} from "react-router-dom"
import driverAddFields from "../../form-fields/driver-add"


export default function DriverEdit() {
    // const reqId = props.match.params.id
    const navigate = useNavigate()
    const {driverId} = useParams()
    const baseDriver: any = {}
    const [driver, setDriver] = useState(baseDriver)
    const baseErrors: any = {}
    const [errors, setErrors] = useState(baseErrors)
    useEffect(() => {
        const fetchData = async () => {
            const res = await myFetch(
                `${SETTINGS.SITE_URL}/api/v1/drivers/${driverId}/`,
                {},
                navigate,
            )
            setDriver(await res.json())
        }
        fetchData()
    }, [driverId, navigate])
    return <>
        <div className="driver-page d-flex flex-wrap">
            <div className="page-top page-top--black d-flex items-center">
                <div className="page-top__left d-flex items-center">
                    <a href="" className="page-top__back circle-center">
                        <span className="icon-angle-left"></span>
                    </a>
                    <span className="page-top__title">{`Водитель ID ${driver.id}`}</span>
                    <div className="page-top__status weekend d-flex items-center">Выходной</div>
                </div>

                <div className="page-top__right d-flex items-center">
                    <div className="page-top__block d-flex items-center">
                        <div className="page-top__block-icon circle-center">
                            <span className="icon-check-circle"></span>
                        </div>
                        Заявок завершено <b>· 582</b>
                    </div>

                    <div className="page-top__block d-flex items-center">
                        <div className="page-top__block-icon circle-center">
                            <span className="icon-calendar"></span>
                        </div>
                        Выходы на линию <b>· 153 дней</b>
                    </div>

                    <div className="page-top__block d-flex items-center">
                        <div className="page-top__block-icon circle-center">
                            <span className="icon-truck"></span>
                        </div>
                        Авто <b>· KAMAZ · 8 кубов · K213KK 116</b>
                    </div>

                    <div className="page-top__block d-flex items-center">
                        <div className="page-top__block-icon circle-center">
                            <span className="icon-phone"></span>
                        </div>
                        Телефон <b>· +7 943 434-05-63</b>
                    </div>
                </div>
            </div>

            <div className="driver-page__content">
                <MyForm
                    fields={driverAddFields}
                    method="PUT"
                    returnUrl={`/drivers/`}
                    formUrl={`${SETTINGS.SITE_URL}/api/v1/drivers/${driverId}/`}
                    state={driver}
                    setState={setDriver}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>

            <div className="driver-page__schedule">
                <h2 className="form__title">График работы</h2>
                <div className="driver-page__schedule-calendar">
                    Calendar here
                </div>
                <div className="details-list d-flex flex-wrap justify-between">
                    <div className="details-list__item d-flex flex-column justify-center">
                        <span className="details-list__item-title">Всего заявок</span>
                        <span className="details-list__item-value">309</span>
                        <span className="details-list__item-icon icon-routing"></span>
                    </div>

                    <div className="details-list__item d-flex flex-column justify-center">
                        <span className="details-list__item-title">Заявок в работе</span>
                        <span className="details-list__item-value">7</span>
                        <span className="details-list__item-icon icon-refresh-circle"></span>
                    </div>

                    <div className="details-list__item d-flex flex-column justify-center">
                        <span className="details-list__item-title">Объектов</span>
                        <span className="details-list__item-value">105</span>
                        <span className="details-list__item-icon icon-building"></span>
                    </div>

                    <div className="details-list__item d-flex flex-column justify-center">
                        <span className="details-list__item-title">Наших контейнеров</span>
                        <span className="details-list__item-value">12</span>
                        <span className="details-list__item-icon icon-box"></span>
                    </div>
                </div>
            </div>

            <div className="driver-page__table">
                <div className="driver-page__table-top d-flex justify-between items-center">
                    <h2 className="form__title">Заявки водителя</h2>
                </div>

                <table className="driver-table table">
                    <thead className="table__top">
                        <tr className="table__top-tr">
                            <th className="table__top-th th-id">
                                <div className="d-flex items-center">
                                    <label className="table__checkbox">
                                        <input type="checkbox" className="table__checkbox-input"/>
                                        <div className="table__checkbox-block d-flex block-center transition">
                                            <span className="icon-check"></span>
                                        </div>
                                    </label>
                                    Id
                                </div>
                            </th>
                            <th className="table__top-th th-status">Статус</th>
                            <th className="table__top-th th-payment">Оплата</th>
                            <th className="table__top-th th-client">Клиент</th>
                            <th className="table__top-th th-address">Адрес объекта</th>
                            <th className="table__top-th th-phone">Номер телефона</th>
                            <th className="table__top-th th-driver">Водитель</th>
                            <th className="table__top-th th-car">Автомашина</th>
                            <th className="table__top-th th-date">Дата заявки</th>
                            <th className="table__top-th th-type">Тип заявки</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table__row">
                            <td className="table__td td-id">
                                <div className="table__td-inner d-flex items-center">
                                    <label className="table__checkbox">
                                        <input type="checkbox" className="table__checkbox-input"/>
                                        <div className="table__checkbox-block d-flex block-center transition">
                                            <span className="icon-check"></span>
                                        </div>
                                    </label>
                                    3050
                                </div>
                            </td>
                            <td className="table__td td-status">
                                <div className="table__td-inner d-flex items-center">
                                    <div className="td-status__block in-work d-flex items-center">{/* classes for statuses: in-work - В работе, completed - Завершен */}
                                        В работе
                                    </div>
                                </div>
                            </td>
                            <td className="table__td td-payment">
                                <div className="table__td-inner d-flex items-center">
                                    <div className="td-payment__block unpaid d-flex items-center">{/* classes for statuses: unpaid - Неоплачен, paid - Оплачен */}
                                        Неоплачен
                                    </div>
                                </div>
                            </td>
                            <td className="table__td td-client">
                                <div className="table__td-inner d-flex items-center">
                                    ОАО СтройСмарт
                                </div>
                            </td>
                            <td className="table__td td-address">
                                <div className="table__td-inner d-flex items-center">
                                    Курмакаев Д.
                                </div>
                            </td>
                            <td className="table__td td-phone">
                                <div className="table__td-inner d-flex items-center">
                                    <a href="tel:+79434340563">+7 943 434-05-63</a>
                                </div>
                            </td>
                            <td className="table__td td-driver">
                                <div className="table__td-inner d-flex items-center">
                                    Максимов Л.
                                </div>
                            </td>
                            <td className="table__td td-car">
                                <div className="table__td-inner d-flex items-center">
                                    K213KK 116
                                </div>
                            </td>
                            <td className="table__td td-date">
                                <div className="table__td-inner d-flex items-center">
                                    15 май 2024
                                </div>
                            </td>
                            <td className="table__td td-type">
                                <div className="table__td-inner d-flex items-center">
                                    Поставил - Забрал
                                </div>
                            </td>
                            <a href="" className="table__move circle-center transition">
                                <span className="icon-arrow-right transition"></span>
                            </a>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
