import { Req } from "../../interfaces/req";
import SETTINGS from "../../settings";
import EditInstanceButton from "../../components/EditInstanceButton";
import { exportFileFatch, myFetch } from "../../utils";
import { useEffect, useState } from "react";
import {
  // RadioGroup,
  // Radio,
  // FormControlLabel,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  Box,

  // IconButton,
} from "@mui/material";
import "./ReqList.css";
import {
  NavLink,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ModalTableFields from "../../components/ModalTableFields";
import reqEditFields from "../../form-fields/req-edit";
import { redirect } from "react-router-dom";
import { Select } from "../../components/select/Select";
import { downloadFile } from "../../utils/downloadFile";

const reqDateFilterItems = [
  {
    value: "today",
    label: "Сегодня",
    dayStart: 0,
    dayEnd: 1,
    withoutDate: false,
  },
  {
    value: "yesterday",
    label: "Вчера",
    dayStart: 1,
    dayEnd: 2,
    withoutDate: false,
  },
  {
    value: "week_ago",
    label: "Неделя",
    dayStart: 0,
    dayEnd: 7,
    withoutDate: false,
  },
  {
    value: "month_ago",
    label: "Месяц",
    dayStart: 0,
    dayEnd: 30,
    withoutDate: false,
  },
  {
    value: "year_ago",
    label: "Год",
    dayStart: 0,
    dayEnd: 365,
    withoutDate: false,
  },
  {
    value: "without_date",
    label: "Без даты",
    dayStart: 0,
    dayEnd: 0,
    withoutDate: true,
  },
];

const STATUS_CLASSES: any = {
  "Водитель назначен": "appointed",
  Завершена: "completed",
  "В работе": "in-work",
  Новая: "new",
};

const reqStatusFilterItems = [
  {
    value: "ALL",
    label: "Все",
    aggKey: "count",
  },
  {
    value: "Новая",
    label: "Новые",
    aggKey: "new_count",
  },
  {
    value: "Водитель назначен",
    label: "Водитель назначен",
    aggKey: "driver_appointed_count",
  },
  {
    value: "В работе",
    label: "В работе",
    aggKey: "in_work_count",
  },
  {
    value: "Завершена",
    label: "Завершены",
    aggKey: "finished_count",
  },
  //   {
  //     value: "Проблема",
  //     label: "Проблемы",
  //     aggKey: "have_problems_count",
  //   },
];

const reqPaymentKind = [
  {
    value: "Не оплачен",
    label: "Неоплаченные",
    aggKey: "not_paid_count",
  },
  {
    value: "Оплачен",
    label: "Оплаченные",
    aggKey: "paid_count",
  },
];

const reqTableFields = [
  {
    mykey: "status",
    label: "Статус",
  },
  {
    mykey: "paid",
    label: "Оплата",
  },
  {
    mykey: "client",
    label: "Клиент",
  },
  {
    mykey: "container",
    label: "Контейнер",
  },
  {
    mykey: "car",
    label: "Машина",
  },
  {
    mykey: "date",
    label: "Дата заявки",
  },
  {
    mykey: "amount",
    label: "Дата заявки",
  },
  {
    mykey: "type",
    label: "Тип заявки",
  },
];

export default function ReqList() {
  const navigate = useNavigate();
  const location = useLocation();

  const { me }: { me: any } = useOutletContext();
  const [reqList, setReqList]: any[] = useState([]);
  const [counts, setCounts]: any[] = useState({
    count: 0,
    new_count: 0,
    finished_count: 0,
    in_work_count: 0,
    driver_appointed_count: 0,
    paid_count: 0,
    not_paid_count: 0,
  });

  let sd1 = localStorage.getItem("reqListStartDate");
  let sdZero = new Date();
  if (sd1 !== null) {
    sdZero.setTime(parseInt(sd1!));
  }

  let ed1 = localStorage.getItem("reqListEndDate");
  let edZero = new Date();
  if (ed1 !== null) {
    edZero.setTime(parseInt(ed1!));
  }
  const [startDate, setStartDate] = useState(sdZero);
  const [endDate, setEndDate] = useState(edZero);

  let dk = localStorage.getItem("reqListDateKind");
  if (dk === null) {
    dk = "today";
  }
  const [dateKind, setDateKind] = useState(dk);
  const [query, setQuery] = useState("");
  const [currentStatus, setCurrentStatus] = useState("ALL");
  const [currentPaymentStatus, setCurrentPaymentStatus] = useState("ALL");

  useEffect(() => {
    getReqResult({
      navigate,
      startDate,
      endDate,
      query,
      currentStatus,
      currentPaymentStatus,
    }).then(
      ({
        results,
        count,
        new_count,
        driver_appointed_count,
        in_work_count,
        finished_count,
        not_paid_count,
        paid_count,
      }: any) => {
        setReqList(results);
        setCounts({
          in_work_count,
          new_count,
          count,
          finished_count,
          not_paid_count,
          paid_count,
          driver_appointed_count,
        });
      }
    );
  }, [
    startDate,
    endDate,
    query,
    currentStatus,
    currentPaymentStatus,
    navigate,
  ]);
  const [showClearIconQuery, setShowClearIconQuery] = useState("none");

  const handleChangeQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setQuery(event.target.value);
  };

  const handleClickClearQuery = (): void => {
    // TODO: Clear the search input
    //   console.log("clicked the clear icon...");
    setQuery("");
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);
  const reqTableFieldsString = localStorage.getItem("reqTableFields");
  let reqTableFieldsObj;
  if (reqTableFieldsString) {
    reqTableFieldsObj = JSON.parse(reqTableFieldsString);
  } else {
    reqTableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [
    any,
    (state: any) => void
  ] = useState(reqTableFieldsObj);

  const handleExportFile = async () => {
    const start_date = dayjs(startDate).format("YYYY-MM-DD");
    const end_date = dayjs(endDate).format("YYYY-MM-DD");
    const newQuery = query.replace(/\s/g, "+");
    const params = {
      start_date: start_date,
      end_date: end_date,
      query: newQuery,
      status: currentStatus,
      payment_status: currentPaymentStatus,
    };

    const response = await exportFileFatch(location.pathname, navigate, params);
    try {
      await downloadFile(response, location.pathname);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="applications-page">
        <ModalTableFields
          state={tableFieldIsClosed}
          setState={setTableFieldIsClosed}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          tableFields={reqTableFields}
        />
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Заявки · {counts.count}</span>
            <span
              className="page-top__left-settings icon-setting transition"
              onClick={openModal}
            ></span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__filter d-flex">
              {reqDateFilterItems.map(
                (item: {
                  value: string;
                  label: string;
                  dayStart: number;
                  dayEnd: number;
                  withoutDate: boolean;
                }) => {
                  const classList = [
                    "page-top__filter-item",
                    "d-flex items-center",
                    "transition",
                    dateKind === item.value ? "is-active" : "",
                  ].join(" ");
                  return (
                    <div
                      key={item.value}
                      className={classList}
                      onClick={() => {
                        setDateKind(item.value);
                        localStorage.setItem("reqListDateKind", item.value);
                        if (item.withoutDate) {
                          localStorage.setItem("reqListStartDate", "0");
                          localStorage.setItem("reqListEndDate", "0");
                          setStartDate(new Date(0));
                          setEndDate(new Date(0));
                        } else {
                          const sd = new Date(
                            new Date().getTime() -
                              item.dayEnd * 24 * 60 * 60 * 1000
                          );
                          localStorage.setItem(
                            "reqListStartDate",
                            sd.getTime().toString()
                          );
                          const ed = new Date(
                            new Date().getTime() -
                              item.dayStart * 24 * 60 * 60 * 1000
                          );
                          localStorage.setItem(
                            "reqListEndDate",
                            ed.getTime().toString()
                          );
                          setStartDate(sd);
                          setEndDate(ed);
                        }
                      }}
                    >
                      {item.label}
                    </div>
                  );
                }
              )}
            </div>

            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    localStorage.setItem("reqListDateKind", "by_date");
                    localStorage.setItem(
                      "reqListStartDate",
                      date!.getTime().toString()
                    );
                    setDateKind("by_date");
                    setStartDate(date!);
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                  // showYearPicker
                />
                &nbsp;-&nbsp;
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date!);
                    setDateKind("by_date");
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                  // showYearPicker
                />
              </>
            </div>

            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIconQuery }}
                      onClick={handleClickClearQuery}
                    >
                      <span className="icon-close"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{" "}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink
              to={"/reqs/add/"}
              className="page-top__button d-flex items-center transition"
            >
              Новая заявка
            </NavLink>
          </div>
        </div>

        <div className="page-content">
          <div className="applications-page__top d-flex items-center justify-between pr-10">
            <div className="applications-page__filter d-flex">
              {reqStatusFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    "applications-page__filter-item d-flex items-center transition" +
                    (item.value === currentStatus ? " is-active" : "");
                  return (
                    <div
                      className={classList}
                      onClick={() => {
                        setCurrentStatus(item.value);
                        setCurrentPaymentStatus("ALL");
                      }}
                    >{`${item.label} · ${counts[item.aggKey]}`}</div>
                  );
                }
              )}
              {reqPaymentKind.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  return (
                    <div
                      className={
                        "applications-page__filter-item d-flex items-center transition" +
                        (item.value === currentPaymentStatus
                          ? " is-active"
                          : "")
                      }
                      onClick={() => {
                        setCurrentPaymentStatus(item.value);
                        setCurrentStatus("ALL");
                      }}
                    >
                      {`${item.label} · ${counts[item.aggKey]}`}
                    </div>
                  );
                }
              )}
            </div>
            <Box style={{ width: "101px" }}>
              <Select
                onChange={handleExportFile}
                itemList={["Экспорт в xlsx"]}
                value={""}
                label={"Действия"}
              />
            </Box>
          </div>

          <div className="applications-page__table">
            <table className="reqs-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input
                          type="checkbox"
                          className="table__checkbox-input"
                        />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      id
                    </div>
                  </th>
                  {!tableFieldIsClosed.status && (
                    <th className="table__top-th th-status">Статус</th>
                  )}
                  {!tableFieldIsClosed.paid && (
                    <th className="table__top-th th-paid">Оплата</th>
                  )}
                  {!tableFieldIsClosed.client && (
                    <th className="table__top-th th-client">Клиент</th>
                  )}
                  <th className="table__top-th th-address">Адрес</th>
                  {!tableFieldIsClosed.container && (
                    <th className="table__top-th th-container">Контейнер</th>
                  )}
                  {!tableFieldIsClosed.car && (
                    <th className="table__top-th th-car">Машина</th>
                  )}
                  {!tableFieldIsClosed.date && (
                    <th className="table__top-th th-date">Дата заявки</th>
                  )}
                  {!tableFieldIsClosed.amount && (
                    <th className="table__top-th th-amount">Сумма</th>
                  )}
                  {!tableFieldIsClosed.type && (
                    <th className="table__top-th th-type">Тип заявки</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {reqList &&
                  reqList.map((req: Req) => {
                    return (
                      <tr className="table__row" key={req.id}>
                        <td className="table__td td-id">
                          <div className="table__td-inner d-flex items-center">
                            <label className="table__checkbox">
                              <input
                                type="checkbox"
                                className="table__checkbox-input"
                              />
                              <div className="table__checkbox-block d-flex block-center transition">
                                <span className="icon-check"></span>
                              </div>
                            </label>
                            {req.id}
                          </div>
                        </td>

                        {!tableFieldIsClosed.status && (
                          <td className="table__td td-status">
                            <div className="table__td-inner d-flex items-center">
                              <div
                                className={`td-status__block ${
                                  STATUS_CLASSES[req.status] || "new"
                                } d-flex items-center`}
                              >
                                {/* classes for statuses: new - Новая, appointed - Назначен, completed - Завершен, in-work - В работе */}
                                {req.status}
                              </div>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.paid && (
                          <td className="table__td td-paid">
                            <div className="table__td-inner d-flex items-center">
                              <div
                                className={`td-paid__block ${
                                  req.payment_status === "Оплачен"
                                    ? "paid"
                                    : "not-paid"
                                } d-flex items-center`}
                              >
                                {/* classes for payment: paid - Оплачен, not-paid - Неоплачен */}
                                {req.payment_status}
                              </div>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.client && (
                          <td className="table__td td-client">
                            <div className="table__td-inner d-flex items-center">
                              {req.client?.name}
                            </div>
                          </td>
                        )}
                        <td className="table__td td-address">
                          <div className="table__td-inner d-flex items-center">
                            {req.address?.location}
                          </div>
                        </td>
                        {!tableFieldIsClosed.container && (
                          <td className="table__td td-container">
                            <div className="table__td-inner d-flex items-center">
                              {req.container?.num}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.car && (
                          <td className="table__td td-car">
                            <div className="table__td-inner d-flex flex-column">
                              <span className="td-car__number">
                                {req.vehicle?.license_plate}
                              </span>
                              <span className="td-car__owner">
                                {req.driver?.last_name} {req.driver?.first_name}{" "}
                                {req.driver?.second_name}
                              </span>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.date && (
                          <td className="table__td td-date">
                            <div className="table__td-inner d-flex items-center">
                              {req.req_date}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.amount && (
                          <td className="table__td td-amount">
                            <div className="table__td-inner d-flex items-center">
                              {req.total_sum}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.type && (
                          <td className="table__td td-type">
                            <div className="table__td-inner d-flex items-center">
                              {req.req_kind}
                              {/*<EditInstanceButton url={`/reqs/${req.id}/edit/`} />*/}
                            </div>
                          </td>
                        )}
                        <a
                          href={`/reqs/${req.id}/edit/`}
                          className="table__edit circle-center transition"
                        >
                          <span className="icon-edit"></span>
                        </a>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getReqResult({
  navigate,
  startDate,
  endDate,
  query,
  currentStatus,
  currentPaymentStatus,
}: {
  navigate: (path: string) => void;
  startDate: Date;
  endDate: Date;
  query: string;
  currentStatus: string;
  currentPaymentStatus: string;
}) {
  const start_date = dayjs(startDate).format("YYYY-MM-DD");
  const end_date = dayjs(endDate).format("YYYY-MM-DD");
  const newQuery = query.replace(/\s/g, "+");

  // alert(currentPaymentStatus)
  const res = await myFetch(
    `${SETTINGS.SITE_URL}/api/v1/reqs/?start_date=${start_date}&end_date=${end_date}&query=${newQuery}&status=${currentStatus}&payment_status=${currentPaymentStatus}`,
    {},
    navigate
  );
  if (!res) {
    redirect("/login");
  }
  const reqResult: {
    results: Req[];
    count: number;
    new_count: number;
    finished_count: number;
    paid_count: number;
    not_paid_count: number;
    driver_appointed_count: number;
    in_work_count: number;
  } = await res.json();
  return reqResult;
}
